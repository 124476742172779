import * as React from "react";

import Box from "../components/Box";
import Layout from "../components/Layout";
import Container from "../components/Container";
import Link from "../components/Link";

import simpleFaqLogo from "../images/simple-faq-logo-3.svg";

const IndexPage = () => {
  return (
    <Layout title="Bitsolar Labs">
      <Box as="section" marginTop={["0rem", "5rem", "10rem"]}>
        <Container textAlign={["", "center"]}>
          <h2>
            We build <u>simple</u> Shopify apps for e-commerce store owners
            <br />
            that want to sell more products.
          </h2>
        </Container>
      </Box>
      <Box as="section" marginTop={["5rem", "10rem"]} flexGrow="1">
        <Container display="flex" justifyContent="center">
          <Link href="/simple-faq">
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Box as="h3" marginBottom="0.5rem">
                Simple FAQ
              </Box>
              <img src={simpleFaqLogo} height="75" />
            </Box>
          </Link>
        </Container>
      </Box>
    </Layout>
  );
};

export default IndexPage;
